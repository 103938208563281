var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row no-gutters" },
    [
      _c("context-title", {
        staticClass: "col-12",
        attrs: { passedActions: _vm.titleBarActions, title: "Mod Groups" },
      }),
      _vm.editMode ? _c("span", { staticClass: "edit-bar col-12" }) : _vm._e(),
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c(
            "div",
            { staticClass: "row no-gutters" },
            [
              _c(
                "router-link",
                {
                  staticClass: "col",
                  attrs: {
                    to: {
                      name: "store-mod-set-add",
                      params: _vm.$route.params,
                    },
                  },
                },
                [
                  _c("iexplorer-header", { attrs: { logo: "add" } }, [
                    _vm._v("Add new Mod Set"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("iexplorer", {
            attrs: {
              activeItems: _vm.activeItems,
              pendingDelete: _vm.pendingDelete,
              editMode: _vm.editMode,
              children: _vm.sets,
              struct: _vm.struct,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }