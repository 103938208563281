// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../img/add_new_logo.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../img/add_new_upsell.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".iexplorer__header {\n  font-size: 14px;\n  cursor: pointer;\n}\n.iexplorer__header-inner {\n  padding: 18px;\n  color: #93BD20;\n}\n.iexplorer__header-title {\n  margin-left: 12px;\n}\n.iexplorer__header-logo {\n  flex-grow: 0 !important;\n  width: 18px;\n  height: 18px;\n  background-size: contain;\n}\n.iexplorer__header-logo--add {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n}\n.iexplorer__header-logo--upsell {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  background-repeat: no-repeat;\n}", ""]);
// Exports
module.exports = exports;
