<template>
    <div class="iexplorer__header">
        <line-item
        class="row no-gutters iexplorer__header-inner height-xs--8"
        :class="{'justify-content-center': center}">
            <div v-if="logo" class="iexplorer__header-logo" :class="`iexplorer__header-logo--${logo}`"></div>
            <div class="iexplorer__header-title">
                <slot>Explorer Header</slot>
            </div>
        </line-item>
    </div>
</template>

<script>
import lineItem from '../line_item.vue'

export default {
    name: 'ExplorerHeader',
    props: {
        logo: String,
        center: Boolean,
    },
    components: {
        lineItem,
    },
}
</script>

<style lang="scss">
@import '~scss/variables';
@import '~scss/mixins';

.iexplorer__header {
    font-size: fz(sm);
    cursor: pointer;
    
    &-inner {
        padding: spacing(sm);
        color: $brand_color;
    }
    &-title {
        margin-left: 12px;
    }
    &-logo {
        flex-grow: 0 !important;
        width: spacing(sm);
        height: spacing(sm);
        background-size: contain;

        &--add {
            @include background-image('', 'add_new_logo.svg', '../../img/');
        }

        &--upsell {
            @include background-image('', 'add_new_upsell.svg', '../../img/');
        }
    }
}
</style>
