<template>
    <div class="row no-gutters">
        <context-title 
            :passedActions="titleBarActions" 
            class="col-12" 
            title="Mod Groups"
        />

        <span 
            v-if="editMode" 
            class="edit-bar col-12"
        />

        <div class="col-12">
            <div class="row no-gutters">
                <router-link 
                    :to="{name: 'store-mod-set-add', params: $route.params}" 
                    class="col"
                >
                    <iexplorer-header logo="add">Add new Mod Set</iexplorer-header>
                </router-link>
            </div>

            <iexplorer
                :activeItems="activeItems"
                :pendingDelete="pendingDelete"
                :editMode="editMode"
                :children="sets"
                :struct="struct" 
            />
        </div>

    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import clone from 'clone'
import { mapActionButtons } from 'helpers'
import bus from '../store/action_bus'
import contextTitle from '../components/context_title.vue'
import iexplorerHeader from '../components/explorer/iexplorer_header.vue'
import iexplorer from '../components/explorer/iexplorer.vue'

export default {
    name: 'StoreModGroupList',
    components: {
        contextTitle,
        iexplorerHeader,
        iexplorer,
    },
    data() {
        return {
            struct: {
                title: {
                    value: item => item.title,
                },
            },
            activeItems: [],
            pendingDelete: [],
            editMode: false,
        }
    },
    computed: {
        titleBarActions() {
            return mapActionButtons({bus, vm: this})
        },
        sets() {
            return clone(this.modSets)
                .map(this.assignHandlers)
        },
        ...mapState({
            modSets: state => state.modSets,
            activeStore: state => state.activeStore,
        }),
    },
    watch: {
        activeStore() {
            this.resolvePageTitle()
        },
    },
    created() {
        this.resolvePageTitle()
    },
    methods: {
        routeAction(param, route) {
            const params = {
                ...param,
            }

            this.$router.push({...route, params})
        },
        assignHandlers(set) {
            return Object.assign(set, {
                onClick: set => {
                    this.$router.push({
                        name: 'store-mod-set-edit',
                        params: {set: set.id},
                    })
                },
            })
        },
        initModalItems() {
            this.mods.forEach(mod => {
                const match = this.activeItems.find(item => item === mod.id)

                if (match) {
                    this.modalItems.push(match)
                }
            })

            if (this.modalItems.length) {
                this.modalActive = true
            }
        },
        reset() {
            this.activeItems = []
            this.editMode = false
        },
        resolvePageTitle() {
            if (Object.keys(this.activeStore).length) {
                document.title = `${this.activeStore.storeName} - Mod Sets`
            }
        },
        ...mapActions({
            remove: 'deleteMod',
        }),
    },
}
</script>

<style lang="scss">
</style>
