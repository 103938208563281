<template>
    <div> 
        <!-- <div class="subnav row no-gutters">
            <div
                v-for="(label, tab) in tabs"
                :key="tab"
                :class="['subnav-item', 'link', 'col', {'subnav-item--active': tab === activeTab}]"
                activeClass="subnav-item--active"
                @click="setActiveTab(tab)"
            >{{ label }}</div>
        </div> -->
        <!-- <keep-alive>
            <component :is="activeTab" />
        </keep-alive> -->
    </div>
</template>

<script>
import StoreModSetList from '../components/store_mod_set_list.vue'
import StoreModGroupList from '../components/store_mod_group_list.vue'
import StoreModsList from '../components/store_mods_list.vue'

import { mapGetters, mapActions } from 'vuex'

export default {
    name: 'StoreModIndex',
    components: {
        StoreModSetList,
        StoreModGroupList,
        StoreModsList
    },
    data() {
        return {
            tabs: {
                'StoreModSetList' : 'MOD SETS',
                'StoreModGroupList' : 'MOD GROUPS',
                'StoreModsList' : 'MODS'
                
            }
        }
    },
    computed: {
        ...mapGetters('ModEditStore', [
            'activeTab'
        ])
    },
    methods: {
        ...mapActions('ModEditStore', [
            'setActiveTab'
        ])
    }
}
</script>

<style lang="scss" scoped>
@import '../scss/variables';
@import '../scss/mixins';

.subnav {
    box-shadow: inset 0 -5px 0 $grey_light_1;

    &-item {
        padding-top: spacing(md);
        padding-bottom: spacing(md);
        cursor: pointer;
        text-transform: uppercase;
        text-align: center;

        &--active, &:hover, &:focus {
            color: $brand_color;
            box-shadow: inset 0 -5px 0 $brand_color;
        }

        &--sm {
            padding-top: spacing(xs);
            padding-bottom: spacing(xs);
            font-size: fz(sm);
            color: $grey_dark_1;
        }
    }
}
</style>
